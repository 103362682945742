import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "d-flex justify-content-end page-heading-toolbar" }
const _hoisted_3 = { class: "page-heading-toolbar--col" }
const _hoisted_4 = { class: "page-heading-toolbar--col" }
const _hoisted_5 = { class: "page-heading-toolbar--col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_create_user_dialog = _resolveComponent("create-user-dialog")!
  const _component_ShadowControl = _resolveComponent("ShadowControl")!
  const _component_SelectDropdown = _resolveComponent("SelectDropdown")!
  const _component_BtnContent = _resolveComponent("BtnContent")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_PageMainHeading = _resolveComponent("PageMainHeading")!
  const _component_AdminsTable = _resolveComponent("AdminsTable")!
  const _component_AppTableController = _resolveComponent("AppTableController")!
  const _component_DeleteUserDialog = _resolveComponent("DeleteUserDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_create_user_dialog, {
        title: "Create Admin",
        opened: _ctx.createAdminDialogOpened,
        loading: _ctx.isLoading,
        "can-cancel": true,
        "end-create": _ctx.endCreateAdmin,
        onHide: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleCreateAdminDialog(false)))
      }, null, 8, ["opened", "loading", "end-create"]),
      _createVNode(_component_PageMainHeading, { title: "Admins" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ShadowControl, {
                id: "admins-search",
                class: "search-control",
                icon: "search",
                placeholder: "Search...",
                name: "admins-search",
                "input-timeout": true,
                onChange: _ctx.searchHandler
              }, null, 8, ["onChange"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_SelectDropdown, {
                "on-select": _ctx.onFilterSelect,
                list: _ctx.filter
              }, null, 8, ["on-select", "list"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_AppButton, {
                type: "secondary",
                size: "lg",
                onClick: _ctx.toggleCreateAdminDialog
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BtnContent, {
                    icon: "plus",
                    label: "Create Admin"
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_AppTableController, {
      "show-table": !!_ctx.accountsList.length,
      "search-query": _ctx.searchQuery,
      "empty-button-text": "Create Admin",
      "empty-key": "admins",
      "empty-icon": "admins",
      onEmptyButtonClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.createAdminDialogOpened = true))
    }, {
      default: _withCtx(() => [
        (_ctx.accountsList.length)
          ? (_openBlock(), _createBlock(_component_AdminsTable, {
              key: 0,
              "accounts-list": _ctx.accountsList,
              "total-rows-count": _ctx.totalAccountsCount,
              "view-configuration": _ctx.viewConfiguration,
              sort: _ctx.sortConfig,
              onDelete: _ctx.deleteUserHandler,
              onChangeViewConfiguration: _ctx.changeViewConfigurationHandler
            }, null, 8, ["accounts-list", "total-rows-count", "view-configuration", "sort", "onDelete", "onChangeViewConfiguration"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["show-table", "search-query"]),
    _createVNode(_component_DeleteUserDialog, {
      opened: _ctx.deleteUserDialogOpened,
      "user-name": _ctx.userInfo.name,
      "user-email": _ctx.userInfo.email,
      "user-photo": _ctx.userInfo.avatar,
      onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteUserDialogOpened = false)),
      onConfirm: _ctx.deleteUser
    }, null, 8, ["opened", "user-name", "user-email", "user-photo", "onConfirm"])
  ], 64))
}